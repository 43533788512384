import { Carousel } from "../components/Carousel";
export const ImageBoard = () => {
  return (
    <div className="flex pb-3 lg:flex-row flex-col">
      <div className="flex justify-content items-center p-10 lg:w-[50%]">
        <h2 className="text-xl sm:text-3xl md:text-2xl xl:text-4xl font-text">
          Discover a dynamic freelance production company rooted in the scenic
          landscapes of Norfolk and Suffolk. Founded by the talented duo of
          Callum O’Brien and Jasmine Lloyd, both seasoned Media Graduates, our
          synergy has fueled captivating projects since 2016.
        </h2>
      </div>
      <Carousel />
    </div>
  );
};
