import { Header, ImageBoard, Portfolio, Services, Inquiries } from "./views";
import { Section } from "./components/Section";
import { Br } from "./components/BreakLine";

function App() {
  return (
    <div className="bg-white h-full w-full pb-20">
      <Header />
      <ImageBoard />
      <Br />
      <Section SectionItem={Portfolio} />
      <Br />
      <Section SectionItem={Services} />
      <Br />
      <Section SectionItem={Inquiries} />
    </div>
  );
}

export default App;
