export const ServiceItem = ({ identifier }) => {
  return (
    <div className="flex flex-col justify-content lg:w-[31%] lg:min-w-[350px] mb-3 space-y-2 bg-gray-200 p-4 shadow-xl rounded-xl">
      <img
        src={identifier.imgUrl}
        className="border border-black border-2 border-slate-800 rounded-xl"
      />
      <h3 className="font-headings text-5xl p-2 self-center">
        {identifier.title}
      </h3>
      <p className="font-text p-4 text-2xl">{identifier.description}</p>
    </div>
  );
};
