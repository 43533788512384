const services = {
    liveEvents: {
        title: "Live Events",
        description: "Immerse yourself in the vibrancy of live events captured with precision and passion. From electrifying band performances and lively festivals to timeless wedding memories, our lens embraces every moment you desire to immortalize on film.",
        imgUrl: "services_live_events.png"
    },
    shortFilms: {
        title: "Short Films",
        description: "Explore our portfolio filled with captivating short films, including projects crafted for esteemed organizations like the National Trust and Film Suffolk.",
        imgUrl: "services_short_films.png"
    },
    commercial: {
        title: "Commercial",
        description: "Elevate your brand with captivating commercial videos tailored to showcase your business's unique essence. With a proven track record of crafting engaging E-learning videos for clients like Stephen Green & Associates, and informative training videos for KM First Aid. One of our commissioned videos even clinched victory in a competition, securing vital grant funds for a local church's renovations.",
        imgUrl: "services_commercial.png"
    }
}
export default services