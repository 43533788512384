const numOfPictures = 8;

export const Carousel = () => {
  const imageCarousel = Array(numOfPictures * 2)
    .fill(1)
    .map((el, i) => {
      return `p-${i % numOfPictures}.jpg`;
    });
  const cs = ` text-xl w-full w-[33.33%] shrink-0`;
  return (
    <div className="w-[100%] relative overflow-hidden pb-1">
      <div className="z-[15] absolute shrink-0 inset-y-0 w-[40%] h-[100%] bg-gradient-to-l from-transparent to-white"></div>
      <div className="flex items-center z-10 transition-transform animate-carousel">
        {imageCarousel.map((image) => {
          return (
            <div className="text-xl w-full sm:w-[33.33%] shrink-0">
              <img src={image} width="100%" height="100%" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
