import { useState, useEffect } from "react";
import { HeaderButton } from "../components/HeaderButton";
import YoutubeIcon from "../icons/YoutubeIcon";
export const Header = () => {
  const [visible, setVisible] = useState(true);
  const [yOffset, setYOffset] = useState(window.scrollY);
  const [showHeaderButtons, setShowHeaderButtons] = useState(true);
  const handleSizeChange = () => {
    console.log(window.innerWidth)
    setShowHeaderButtons((window.innerWidth > 700));
  };
  useEffect(() => {
    window.addEventListener("resize", handleSizeChange);
    return () => {
      window.removeEventListener("resize", handleSizeChange);
    };
  });

  const handleScroll = () => {
    if (window.scrollY < yOffset) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    setYOffset(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [window.scrollY]);
  return (
    <div
      className="text-white bg-black sticky top-0 z-[20] transition self-center"
      style={{ transform: visible ? "" : "translateY(-100%)" }}
    >
      <div className="flex items-center md:justify-evenly justify-between">
        <h1 className="pl-2 w-[33%] text-3xl text-center">Panemone Productions</h1>

        {showHeaderButtons ? (
          <div className=" w-full flex justify-evenly">
            <HeaderButton text="Portfolio" />
            <HeaderButton text="Services" />
            <HeaderButton text="Inquiries" />
          </div>
        ) : (
          ""
        )}

        <a
          className="md:self-center"
          href="https://www.youtube.com/channel/UCIWC4GDrIj8FjdDAVO0q9pA"
        >
          <YoutubeIcon className="w-10 mr-5" />
        </a>
      </div>
    </div>
  );
};
