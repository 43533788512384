import { ServiceItem } from "../components/ServiceItem";
import { Title } from "../components/Title";
import services from "../content/services";
export const Services = () => {
  return (
    <div id="services">
      <Title name={"Services"} />
      <div className="flex">
        <div className="lg:flex justify-evenly">
          <ServiceItem identifier={services.liveEvents} />
          <ServiceItem identifier={services.shortFilms} />
          <ServiceItem identifier={services.commercial} />
        </div>
      </div>
    </div>
  );
};
