import { Title } from "../components/Title";
import { contact } from "../content/contact";
const InquirySection = ({ type }) => {
  const { heading, content, Icon, iconWidth } = contact[type];
  return (
    <div className="flex w-full flex-col justify-evenly w-[50%] items-center">
      <h1 className="text-4xl p-2 text-center">{heading}</h1>
      <p>{content}</p>
      <Icon width={iconWidth} />
    </div>
  );
};

export const Inquiries = () => {
  return (
    <div id="inquiries">
      <Title name={"Inquiries"} />
      <div className="md:flex p-4">
        <div className="overflow-hidden flex md:w-[50%] min-w-fit md:rounded-l-xl md:rounded-tr-none rounded-t-xl bg-gray-200 shadow-xl p-4">
          <InquirySection type="email" />
        </div>
        <div className=" overflow-hidden flex md:w-[50%] min-w-fit rounded-b-xl md:rounded-b-none md:rounded-r-xl  bg-gray-300 shadow-xl p-4">
          <InquirySection type="phone" />
        </div>
      </div>
    </div>
  );
};
