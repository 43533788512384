import { Title } from "../components/Title"
import YouTube from 'react-youtube';
export const Portfolio = () => {
    return (
        <div id='portfolio' className="flex flex-col" >
            <Title name={'Portfolio'} />
            <YouTube videoId="8mAITcNt710" className="flex justify-self self-center" opts={{playerVars: {playsinline:1 }}}/>
        </div>
    )
}

